/*
The headerInit function call below is for the DSC Header (https://developer.salesforce.com/sections/dsc-header.json). The function is defined in this script: https://s3.amazonaws.com/dfc-assets-staging/scripts/index.js?v=4, which is imported via script tag in `<Layout />`. The function needs to be called on route changes in order to re-initialize the header. Otherwise the header will not be interactive (it won't toggle `slds-hide`).
*/

exports.onRouteUpdate = ({ prevLocation }) => {
  // only run `headerInit()` on client changes after inital render
  if (prevLocation !== null && window.headerInit) {
    window.headerInit(document.querySelector('[data-header]'));
  }
};
